import React from "react";
import { Modal } from 'react-bootstrap';
import './Cart.scss';
import api from "../../../Environment";
import Payment from "./Payment";
// import ReactGA from 'react-ga';
import { t } from "react-multi-lang";
import Helper from "../../Helper/helper";

// ReactGA.modalview('/cart');

class Cart extends Helper {

      constructor(props) {
        super(props);
        this.state = {
            list_payment: [],
            loading: true,
            product_bank_code: "",
            bank_code : "",
            showModalPayment : false,
            dataPropsPayment : [],
            link_payment : null,
            paymentMode: "espay",
            data: {},
            promoCode: null,
            loadingPromoCode: true,
            notifPromoCode: "",
            loadingNotif: true,
        }
        this.handleChangeBank = this.handleChangeBank.bind(this);
      }

    componentDidMount() {
        this.apiListPayment()
      }

      async apiListPayment() {
        const data = {
            sub_profile_id: "",
          };
        await api
          .postMethod("list_payment_espay", data)
          .then(async (response) => {
            // console.log('inilah dia:'+ response.data.data.data)
            this.setState({
                list_payment: await response.data.data.data,
            });
          })
          .catch(function(error) {});
      }

      handleClosePayment() {
        this.setState({ showModalPayment: false });
      }

      handleChangeBank(event) {    
        this.setState({product_bank_code: event.target.value});  
        this.setState({bank_code: event.target[event.target.selectedIndex].getAttribute('data-code')}); 

        
        // console.log(event.target[event.target.selectedIndex].getAttribute('data-code'));
        // console.log(event);
      }

      handleChange = ({ currentTarget: input }) => {
        const data = { ...this.state.data };
        data[input.name] = input.value;
        this.setState({ data });
      };

      handleSubmit = (event) => {
        event.preventDefault();
        // const { toastManager } = this.props;

        let inputData = {
            sub_profile_id: "",
            subscription_id: this.props.dataPropsCart.subscription_id,
            payment_mode: this.state.paymentMode,
            product_bank_code: this.state.product_bank_code,
            bank_code: this.state.bank_code
        };
        
        if (this.state.promoCode != null) {
            inputData.coupon_code = this.state.data.coupon_code;
        }
        
        if (this.props.isDoublePayment) {
            inputData.payment_model = 3;
            inputData.admin_video_id = this.props.videoDetailsFirst.admin_video_id;
        } else {
            inputData.payment_model = 1;
        }
          
        api
          .postMethod("v4/ppv_and_subscription_payment", inputData)
          .then((response) => {
            if (response.data.success) {
            //   alert('Data berhasil dikirim!')
              this.setState({
                    showModalPayment: true,
                    dataPropsPayment: response.data.data.checkout_details,
                    link_payment: response.data.data.link_payment,
                });

            //   window.location.href = response.data.data.link_payment;
            } else {
              alert(response.data.error_messages)
                // console.log(response.data.messages)
              
            }
          })
          .catch((error) => {
            console.log(error)
            
          });
      };

      handlePromoCode = event => {
        event.preventDefault();
        this.setState({
            loadingContent: t("loading_text"),
            buttonDisable: true
        });
        let inputData = {};
        if (this.props.isDoublePayment) {
            inputData = {
                payment_model: 3,
                subscription_id: this.props.dataPropsCart.subscription_id,
                admin_video_id: this.props.videoDetailsFirst.admin_video_id,
                coupon_code: this.state.data.coupon_code,
            }
        } else{
            inputData = {
                payment_model: 1,
                subscription_id: this.props.dataPropsCart.subscription_id,
                coupon_code: this.state.data.coupon_code,
            }
        }
        api.postMethod("apply/coupon/ppv_and_subscription", inputData)
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        loadingContent: null,
                        buttonDisable: false,
                        loadingPromoCode: false,
                        promoCode: response.data.data,
                        notifPromoCode: "Coupon Applied",
                        loadingNotif: false
                    });
                } else {
                    console.log(response.data.error_messages);
                    this.setState({
                        loadingContent: null,
                        buttonDisable: false,
                        notifPromoCode: response.data.error_messages,
                        loadingNotif: false,
                        promoCode:null,
                        loadingPromoCode: true,
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({ loadingContent: null, buttonDisable: false });
            });
    };

    render(props) {
        const { dataPropsCart, isDoublePayment, videoDetailsFirst } = this.props;
        const {
            data,
            loadingPromoCode,
            promoCode,
            showModalPayment,
            dataPropsPayment,
            link_payment,
            notifPromoCode,
            loadingNotif
          } = this.state;
        let amount = isDoublePayment? videoDetailsFirst.ppv_amount+dataPropsCart.amount : dataPropsCart.amount;
        let total = loadingPromoCode? isDoublePayment? videoDetailsFirst.ppv_amount+dataPropsCart.amount : dataPropsCart.amount : isDoublePayment? (videoDetailsFirst.ppv_amount+dataPropsCart.amount) - promoCode.coupon_amount : dataPropsCart.amount - promoCode.coupon_amount;
        return (
            <Modal className="modal-pay-2" show={this.props.show} onHide={() => this.props.onHide(false)}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="wrapper-pay-2">
                        <div className="section-content">
                            <div className="content-header">
                                    <div className="content-header-title">Cart</div>
                            </div>
                            <div className="content-subs">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="content-subs-title">{dataPropsCart.plan_formatted} Subscription</div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="content-subs-title-price">Rp {this.formatRupiah(dataPropsCart.amount)}</div>
                                        </div>
                                    </div>
                            </div>

                            {isDoublePayment ? 
                            <div className="content-addon">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="content-addon-title">{videoDetailsFirst.title}</div>
                                </div>
                                <div className="col-md-6">
                                    <div className="content-addon-title-price">Rp {this.formatRupiah(videoDetailsFirst.ppv_amount)}</div>
                                </div>
                            </div>
                            </div>
                            :''}

                            <div className="content-amount">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="content-amount-title">Amount</div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="content-amount-title-price">Rp {this.formatRupiah(amount)}</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="content-amount-title">Promo Code Amount</div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="content-amount-title-price">{loadingPromoCode
                                                                    ? "0"
                                                                    : this.formatRupiah(promoCode.coupon_amount)}</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="content-amount-title">Total</div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="content-amount-title-price">Rp {this.formatRupiah(total)}</div>
                                    </div>
                                </div>                             
                            </div>
                            <div className="content-method">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="content-method-title">Payment Method</div>
                                        </div>
                                    </div>                                    
                                    <select className="btn-payment" onChange={this.handleChangeBank} value={this.state.product_bank_code}>
                                        <option value="" disabled selected>Select Payment Method</option>
                                        {this.state.list_payment.map( (item,index) => (
                                            <option value={item.productCode} data-code={item.bankCode} > {item.productName} </option>
                                        ))}
                                    </select>
                            </div>
                            <div className="content-coupon">
                                {/* <input type="text" name="promo-code" id="" placeholder="Have a coupon?" className="input-promo"/> */}
                                <form
                                    className="coupon-form"
                                    onSubmit={
                                        this.handlePromoCode
                                    }
                                >
                                    <div className="form-group mt-3">
                                        <div className="input-group mb-3 mt-1">
                                            <input
                                                type="text"
                                                className="form-control m-0 mb-0"
                                                placeholder="Have a coupon?"
                                                name="coupon_code"
                                                value={
                                                    data.coupon_code
                                                }
                                                onChange={
                                                    this
                                                        .handleChange
                                                }
                                            />
                                            <div className="input-group-append">
                                                <button
                                                    className="btn btn-send"
                                                    type="submit"
                                                >
                                                    {this.state
                                                        .loadingContent !=
                                                    null
                                                        ? this
                                                                .state
                                                                .loadingContent
                                                        : "Send"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>{loadingNotif ? (
                                    ""
                                ) : (
                                    <p className="capitalize">
                                        {notifPromoCode}
                                    </p>
                                )}
                            </div>

                            <div className="content-buy">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="content-buy-title">Buy</div>
                                            <div className="content-buy-title">Rp {this.formatRupiah(total)}</div>
                                        </div>
                                        <div className="col-md-6">
                                            <button id="buy" onClick={this.handleSubmit} className="btn-buy">Buy</button>
                                        </div>
                                    </div>
                            </div>
                            
                        </div>
                        <Payment
                            show={showModalPayment}
                            dataPropsPayment={dataPropsPayment}
                            link_payment={link_payment}
                            onHide={this.handleClosePayment.bind(this)}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default Cart;