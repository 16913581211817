import React from "react";
import { Image, Form, Media, Accordion, Card, Button } from "react-bootstrap";
import "./VideoPlayer.css";
import { Link } from "react-router-dom";
import ReactJWPlayer from "react-jw-player";
import ContentLoader from "../../components/Static/contentLoader";
import Helper from "../../components/Helper/helper";
import api from "../../Environment";
import io from "socket.io-client";
import { apiConstants } from "../../components/Constant/constants";
import ToastDemo from "../Helper/toaster";
import TagManager from "react-gtm-module";

const socket = apiConstants.socketUrl ? io(apiConstants.socketUrl) : "";
const $ = window.$;
class VideoPLayerIndex extends Helper {
  state = {
    loadingFirst: true,
    videoDetailsFirst: null,
    onPlayStarted: false,
    socket: false,
    socketConnection: true,
    socketConnected: false,
    videoPlayerInfo: null,
    loadingPlayerInfo: true,
    currentVolume: 50,
    playButtonClicked: false,
    inputData: {},
    date: new Date(),
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    listBitSecond: null,
    drmConfig: {},
    currentChapter: 1,
    seeking: false,
    seekValue: 0,
    startTime: 0,
    counterDelay: 0,
    timer: null,
    playStartTime: 0,
    accumulatedPlayTime: 0,
    isVideoPlaying: false
  };

  componentDidMount() {
    if (this.props.location.state) {
      window.addEventListener('beforeunload', this.handleBeforeUnload);
      this.setState({
        videoDetailsFirst: this.props.location.state.videoDetailsFirst,
      });
      let inputData = {
        admin_video_id: this.props.location.state.videoDetailsFirst
          .admin_video_id,
      };

      this.setState({ listBitSecond: this.props.location.state.videoDetailsFirst.beat_seconds });

      api
        .postMethod("video_player_info", inputData)
        .then((response) => {
          if (response.data.success === true) {
            let videoPlayerInfo = response.data.data;
            this.setState({
              loadingPlayerInfo: false,
              videoPlayerInfo: videoPlayerInfo,
            });
          } else {
            this.setState({ videoPlayerInfo: response.data });
          }
        })
        .catch(function() {});

      api
        .postMethod("drm_settings")
        .then((response) => {
          if (response.data.success === true) {
            this.setState({
              drmConfig: response.data.data,
            });
          }
        })
        .catch(function() {});

      this.setState({ loadingFirst: false });
      this.userSession();
      this.intervalSession = setInterval(this.userSession, 60000);
    } else {
      window.location = "/home";
    }
  }

  sendDataToGA = (status,playTime) => {
    if (playTime > 0) {
      let video_duration = this.state.playedSeconds
      ? this.state.playedSeconds
      : 0;

      let video_percent =
      video_duration / this.state.duration
        ? Math.round((video_duration / this.state.duration) * 100)
        : 0;

      TagManager.dataLayer({
        gtmId: "GTM-TC2T48C",
        dataLayer: {
          event: "video",
          video_status: status,
          video_title: this.state.videoDetailsFirst.title,
          video_percent: status === "complete" ? 100 : video_percent,
          video_duration: this.state.duration,
          video_provider: "comika",
          total_video_duration_by_user: playTime,
        },
      });
    }
  };

  loadInterval = () => {
    clearInterval(this.state.timer);
    this.setState({ timer: null });

    if (this.state.counterDelay > 0) {
      const timer = setInterval(() => {
        this.setState((prevState) => ({
          counterDelay: prevState.counterDelay - 1,
        }));

        if (this.state.timer && this.state.counterDelay === 0) {
          clearInterval(this.state.timer);
          // console.log('----------->>> API call: continue watching');
          this.addContinueWatching()
        }
      }, 1000);

      this.setState({ timer });
    }
  };

  handleStartTime = () => {
    
      if (this.props.location.state.videoDetailsFirst.seek_time_in_seconds !== "") {
        
        let player = window.jwplayer("my-unique-id");
        let total_duration = player.getDuration();
        let list_waktu = this.state.listBitSecond;
        let current_position = this.props.location.state.videoDetailsFirst.seek_time_in_seconds
        let seek_second = (current_position / total_duration) * total_duration;

        const define_chapter = list_waktu.filter((ch, index) => {
          const ch_next = list_waktu[index + 1];
          return (
            seek_second >= ch && (ch_next === undefined || seek_second < ch_next)
          );
        });

        const chapter = parseInt(define_chapter);

        if (chapter) {
          this.setState({
            currentChapter: chapter,
            startTime : chapter
          });
        }
      }

  }

  handleBufferChange = (state) => {
    this.setState({
      loaded: state.bufferPercent,
    });
  };

  handleChange = e => {
    this.setState({ 
      seekValue: e.target.value,
      played: e.target.value 
    })
  };

  handleMouseUp = () => {
    this.setState({ seeking: false })

    let player = window.jwplayer("my-unique-id");
    let total_duration = player.getDuration();
    let list_waktu = this.state.listBitSecond;
    let current_position = this.state.seekValue;
    let seek_second = (current_position / 100) * total_duration;

    const define_chapter = list_waktu.filter((ch, index) => {
      const ch_next = list_waktu[index + 1];
      return (
        seek_second >= ch && (ch_next === undefined || seek_second < ch_next)
      );
    });

    const chapter = parseInt(define_chapter);

    if (chapter) {
      if (this.state.currentChapter === chapter) {
        return player.seek(seek_second);
      } else {
        this.setState({
          currentChapter: chapter,
        });

        return player.seek(chapter);
      }
    }


  };

  handleMouseDown = () => {
    this.setState({ seeking: true })
  };

  addContinueWatching = () => {
    api
      .postMethod("save/watching/video/v2", { 
        admin_video_id: this.props.location.state.videoDetailsFirst
        .admin_video_id,
        duration : this.state.playedSeconds
      })
      .then((response) => {
        if (response.data.success === true) {
          // console.log('berhasil post continue watching', 'duration :', response.data.data.duration);
        }
      })
      .catch(function() {});
  };

  componentWillUnmount() {
    clearInterval(this.state.timer);

    clearInterval(this.intervalSession);

    this.addContinueWatching();

    window.removeEventListener('beforeunload', this.handleBeforeUnload);

    this.exitPiP();

    this.handleVideoPlayingTrue("progress");
  }

  handleVideoPlayingTrue = (status) => {
    if (this.state.isVideoPlaying) {
      const playEndTime = Date.now();
      const currentPlayTime = Math.floor((playEndTime - this.state.playStartTime) / 1000);
      const accumulatedPlayTime = this.state.accumulatedPlayTime + currentPlayTime;

      this.sendDataToGA(status,accumulatedPlayTime)

      this.setState({ 
        isVideoPlaying : false
      });
    }
  }

  handleVideoPlayingFalse = () => {
    if (!this.state.isVideoPlaying) {
      this.setState({ 
        playStartTime : Date.now(),
        isVideoPlaying : true
      });
    }
  }

  handleBeforeUnload = () => {
    this.addContinueWatching();

    this.handleVideoPlayingTrue("progress");

    clearInterval(this.intervalSession);
  }

  onCompleteVideo = () => {
    this.handleVideoPlayingTrue("complete");

    this.addHistory(this.state.videoDetailsFirst.admin_video_id);
    $("#player-pause-item").hide();
    $("#player-play-item").show();
  };

  onVideoPlay = async () => {
    const player = window.jwplayer("my-unique-id");
    player.setCurrentQuality(1);
    let total_duration = player.getDuration();
    this.setState({ duration: total_duration });

    if (!this.state.seeking) {
      this.handleStartTime();
    }

    this.handleVideoPlayingFalse();
  };

  addHistory = (admin_video_id) => {
    api
      .postMethod("addHistory", { admin_video_id: admin_video_id })
      .then((response) => {
        if (response.data.success === true) {

        } 
      })
      .catch(function() {});
  };

  ref = (player) => {
    this.player = player;
  };

  socketConnectionfun = (userId, accessToken) => {
    if (apiConstants.socketUrl) {
      let videoId = this.state.videoDetailsFirst.admin_video_id;

      socket.on("connect", function() {
      });

      socket.on("connected", function() {
        this.setState({ socketConnected: true });
      });

      socket.on("disconnect", function() {
        this.setState({ socketConnected: false });
      });

      let videoData = [
        {
          sub_profile_id: localStorage.getItem("active_profile_id"),
          admin_video_id: videoId,
          id: userId,
          token: accessToken,
          duration: this.state.playedSeconds,
        },
      ];

      socket.emit("save_continue_watching_video", videoData[0]);
    }
  };

  openFullscreen = (event) => {
    this.exitPiP()
    event.preventDefault();
    const elem = document.getElementById("comika-jwplayer");
    
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }
  }

  closeFullscreen = (event) => {
    event.preventDefault();
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
      }
    }
  }

  exitPiP() {
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture();
    }    
  }

  openPip = (event) => {
    event.preventDefault();
    const videoElement = document.querySelector('video');

    if (videoElement && videoElement.requestPictureInPicture) {
      videoElement.requestPictureInPicture();
    }    
  }

  onPauseVideo = async () => {
    const player = window.jwplayer("my-unique-id");
    player.pause();
    $("#player-pause-item").hide();
    $("#player-play-item").show();

    this.handleVideoPlayingTrue("progress");
  };

  onResumeVideo = async () => {
    const player = window.jwplayer("my-unique-id");
    player.play();
    $("#player-play-item").hide();
    $("#player-pause-item").show();

    this.handleVideoPlayingFalse();
  };

  playerBackward = (event) => {
    event.preventDefault();
    const player = window.jwplayer("my-unique-id");
    if (player.getPosition() > 10) {
      var newPosition = player.getPosition() - 10;
      player.seek(newPosition);
    }
  };

  playerForward = (event) => {
    event.preventDefault();
    const player = window.jwplayer("my-unique-id");
    var newPosition = player.getPosition() + 10;
    player.seek(newPosition);
  };

  playerVolume = (event, value) => {
    event.preventDefault();
    const player = window.jwplayer("my-unique-id");
    const setVolume = parseInt(value);
    if (setVolume <= 0) {
      player.setMute(true);
      if (player.getMute() == true) {
        $("#player-unmute-button-item").hide();
        $("#player-mute-button-item").show();
      }
    } else {
      player.setMute(false);
      if (player.getMute() == false) {
        $("#player-unmute-button-item").show();
        $("#player-mute-button-item").hide();
      }
    }
    player.setVolume(setVolume);
    this.setState({
      currentVolume: value,
    });
  };

  playerPlay = (event) => {
    event.preventDefault();
    const player = window.jwplayer("my-unique-id");
    player.play();
    $("#player-play-item").hide();
    $("#player-pause-item").show();
  };

  playerPause = (event) => {
    event.preventDefault();
    const player = window.jwplayer("my-unique-id");
    player.pause();
    $("#player-pause-item").hide();
    $("#player-play-item").show();
  };

  playerMute = (event) => {
    event.preventDefault();
    const player = window.jwplayer("my-unique-id");
    player.setMute(true);
    $("#player-unmute-button-item").hide();
    $("#player-mute-button-item").show();
    this.setState({
      currentVolume: 0,
    });
  };

  playerUnmute = (event) => {
    event.preventDefault();
    const player = window.jwplayer("my-unique-id");
    player.setMute(false);
    this.setState({
      currentVolume: player.getVolume(),
    });
    $("#player-unmute-button-item").show();
    $("#player-mute-button-item").hide();
  };

  playerDuration = (e) => {
    let player = window.jwplayer("my-unique-id");
    let total_duration = player.getDuration();
    let current_position = e.position;
    let percentage = (current_position / total_duration) * 100;
    if (!this.state.seeking) {
      this.setState({
        played: percentage,
        playedSeconds: current_position,
        counterDelay: 1
      });
    }
    this.loadInterval();

  };

  handlePlayVideo = async (event, admin_video_id) => {
    event.preventDefault();

    let inputData = {
      ...this.state.inputData,
      admin_video_id: admin_video_id,
    };

    await this.onlySingleVideoFirst(inputData);
    if (this.state.videoDetailsFirst.success === false) {
      ToastDemo(
        this.props.toastManager,
        this.state.videoDetailsFirst.error_messages,
        "error"
      );
    } else {
      this.redirectStatus(this.state.videoDetailsFirst);
      this.setState({ playButtonClicked: true });
    }
  };

  setPlaybackspeed = (event, value) => {
    event.preventDefault();
    let player = window.jwplayer("my-unique-id");
    player.setPlaybackRate(value);
    this.setState({
      playbackRate: value,
    });
  };

  playerFullscreenOn = (event) => {
    event.preventDefault();
    let player = window.jwplayer("my-unique-id");
    player.setFullscreen(true);
  };

  playerFullscreenOff = (event) => {
    event.preventDefault();
    this.player.setFullScreen(false);
    $("#player-fullscreen-off").hide();
    $("#player-fullscreen-on").show();
  };

  onReady = () => {
  };

  playerSetCaption = (event, value) => {
    event.preventDefault();
    let player = window.jwplayer("my-unique-id");
    player.setCurrentCaptions(value + 1);
  };

  playerSetTrack = (event, value) => {
    event.preventDefault();
    let player = window.jwplayer("my-unique-id");
    player.setCurrentAudioTrack(value + 1);
  };

  skipIntro = (event, time) => {
    event.preventDefault();
    let player = window.jwplayer("my-unique-id");
    player.seek(time);
    $("#skip-intro-sec").hide();
  };

  render() {
    const {
      played,
      loaded,
      playbackRate,
      listBitSecond,
      duration,
      loadingFirst,
      loadingPlayerInfo,
      drmConfig,
      startTime
    } = this.state;

    const percentload = {
      width: loaded + "%",
    };

    if (this.state.playButtonClicked) {
      const returnToVideo = this.renderRedirectPage(
        this.state.videoDetailsFirst
      );

      if (returnToVideo != null) {
        return returnToVideo;
      }
    }

    let mainVideo, secondVideo, videoTitle;

    if (loadingFirst && loadingPlayerInfo) {
      return <ContentLoader />;
    } else {

      if (this.state.videoDetailsFirst.is_drm) {
        mainVideo =
          "https://admin.comika.id:8080/" +
          this.state.videoDetailsFirst.dash_path +
          "/master.mpd";

        // secondVideo =
        //   "https://admin.comika.id:8080/" +
        //   this.state.videoDetailsFirst.hls_path +
        //   "/master.m3u8";
      } else {
        mainVideo =
          "https://admin.comika.id/uploads/videos/original/main/" +
          this.state.videoDetailsFirst.main_path +
          ".mp4";
      }

      videoTitle = this.state.videoDetailsFirst.title;

      var stitles = [];
      var atrack = [];

      if (
        !this.state.loadingPlayerInfo &&
        this.state.videoPlayerInfo.subtitles.length > 0
      ) {
        this.state.videoPlayerInfo.subtitles.forEach((resource) => {
          stitles.push({
            file: resource.subtitle,
            label: resource.language,
            kind: "captions",
          });
        });
      }

      if (
        !this.state.loadingPlayerInfo &&
        this.state.videoPlayerInfo.audio_tracks.length > 0
      ) {
        this.state.videoPlayerInfo.audio_tracks.forEach((value) => {
          atrack.push({ file: value.audio, name: value.language });
        });
      }

      const playlist = [
        {
          file: mainVideo,
          image: this.state.videoDetailsFirst.default_image,
          captions: stitles,
          starttime: startTime,
          ...(this.state.videoDetailsFirst.is_drm
            ? {
                drm: {
                  widevine: {
                    url: drmConfig.license_widevine,
                  },
                  playready: {
                    url: drmConfig.license_playready,
                  },
                },
              }
            : {}),
        },
      ];

      return (
        <>
          <div className="player-video-sec" id="comika-jwplayer">
            <ReactJWPlayer
              ref={this.ref}
              height="100%"
              width="100%"
              playerId="my-unique-id"
              aspectRatio="inherit"
              playerScript={drmConfig.script_web}
              playlist={playlist}
              onTime={this.playerDuration}
              onAutoStart={this.onVideoPlay}
              onReady={this.onReady}
              onOneHundredPercent={this.onCompleteVideo}
              onPause={this.onPauseVideo}
              onResume={this.onResumeVideo}
              bufferSize={60000}
              onBufferChange={this.handleBufferChange}
              customProps={{
                skin: {
                  name: "Netflix",
                },
                autostart: true,
                mute: false,
              }}
            />

            {this.state.videoDetailsFirst.skip_intro_seconds > 0 ? (
              <div className="skip-intro-sec" id="skip-intro-sec">
                <Button
                  className="btn skip-intro-btn"
                  onClick={(event) =>
                    this.skipIntro(
                      event,
                      this.state.videoDetailsFirst.skip_intro_seconds
                    )
                  }
                >
                  Skip Intro
                </Button>
              </div>
            ) : (
              ""
            )}
            <div className="top-control-sec">
              <Link to="/home">
                <Image
                  src={
                    window.location.origin +
                    "/assets/img/video-player-icons/back-arrow-icon.svg"
                  }
                  alt=""
                  className="back-icon"
                />
              </Link>
            </div>
            <div className="center-control-sec">
              <Link to="#">
                <div className="center-play-icon-sec">
                  <Image
                    src={
                      window.location.origin +
                      "/assets/img/video-player-icons/play-icon.svg"
                    }
                    alt=""
                    className="center-play-icon"
                  />
                </div>
              </Link>
            </div>
            <div className="bottom-control-sec">
              <div className="video-progress-bar-sec">
                <Form className="video-progress-bar">
                  <Form.Group controlId="playerDuration">
                    <div className="box-progress">
                      <div className="loaded-progress" style={percentload}>
                        {" "}
                      </div>
                      {listBitSecond.map((value, index) => {
                        if (value !== 1) {
                          let _percent = (value / duration) * 100;
                          return (
                            <span
                              className="marker-progress"
                              key={index}
                              style={{ marginLeft: _percent + "%" }}
                            ></span>
                          );
                        }
                      })}
                      <input
                        id="seek-cursor"
                        className="cursor-seek"
                        type="range"
                        min={0}
                        max={100}
                        step="any"
                        value={played}
                        onChange={this.handleChange}
                        onMouseUp={this.handleMouseUp}
                        onMouseDown={this.handleMouseDown}
                      />
                    </div>
                  </Form.Group>
                </Form>
                <div className="running-time-sec">
                  <p className="running-time">
                    {this.secondsToTime(
                      this.state.duration - this.state.playedSeconds
                    )}
                  </p>
                </div>
              </div>
              <div className="video-control-bottom-sec">
                <ul className="list-unstyled control-sec">
                  <Media
                    as="li"
                    id="player-play-item"
                    style={{ display: "none" }}
                  >
                    <Link to="#" onClick={(event) => this.playerPlay(event)}>
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/video-player-icons/play-icon.svg"
                        }
                        alt=""
                        className="control-icon"
                      />
                    </Link>
                  </Media>

                  <Media as="li" id="player-pause-item">
                    <Link to="#" onClick={(event) => this.playerPause(event)}>
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/video-player-icons/pause-icon.svg"
                        }
                        alt=""
                        className="control-icon"
                      />
                    </Link>
                  </Media>

                  <Media as="li">
                    <Link
                      to="#"
                      onClick={(event) => this.playerBackward(event)}
                    >
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/video-player-icons/backward-icon.svg"
                        }
                        alt=""
                        className="control-icon"
                      />
                    </Link>
                  </Media>
                  <Media as="li">
                    <Link to="#" onClick={(event) => this.playerForward(event)}>
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/video-player-icons/forward-icon.svg"
                        }
                        alt=""
                        className="control-icon"
                      />
                    </Link>
                  </Media>
                  <Media as="li" className="audio-icon">
                    <Link
                      to="#"
                      id="player-unmute-button-item"
                      onClick={(event) => this.playerMute(event)}
                    >
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/video-player-icons/audio-full-icon.svg"
                        }
                        alt=""
                        className="control-icon"
                      />
                    </Link>
                    <Link
                      to="#"
                      id="player-mute-button-item"
                      onClick={(event) => this.playerUnmute(event)}
                      style={{ display: "none" }}
                    >
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/video-player-icons/audio-mute-icon.svg"
                        }
                        alt=""
                        className="control-icon"
                      />
                    </Link>
                    <div className="audio-progress-bar-sec">
                      <Form className="audio-progress-bar">
                        <Form.Group controlId="playerVolume">
                          <Form.Control
                            type="range"
                            id="player-volume-input"
                            min="0"
                            max="100"
                            step="10"
                            value={this.state.currentVolume}
                            onChange={(event) => {
                              this.playerVolume(
                                event,
                                event.currentTarget.value
                              );
                            }}
                          />
                        </Form.Group>
                      </Form>
                    </div>
                  </Media>
                  <Media as="li">
                    <p className="video-main-title">{videoTitle}</p>
                  </Media>
                </ul>
                <ul className="list-unstyled control-sec resp-center-control">
                  {this.state.videoDetailsFirst.next_admin_video_id ? (
                    <Media as="li" className="next-episode-icon">
                      <Link
                        onClick={(event) =>
                          this.handlePlayVideo(
                            event,
                            this.state.videoDetailsFirst.next_admin_video_id
                          )
                        }
                        to="#"
                      >
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/video-player-icons/next-video-icon.svg"
                          }
                          alt=""
                          className="control-icon"
                        />
                      </Link>
                      <div className="hoverable-next-episode-icon">
                        <Link
                          onClick={(event) =>
                            this.handlePlayVideo(
                              event,
                              this.state.videoDetailsFirst.next_admin_video_id
                            )
                          }
                          to="#"
                        >
                          <div className="header-sec">
                            <h6>Next Episode</h6>
                          </div>
                          <div className="body-sec">
                            <div className="next-video-sec">
                              <Image
                                src={
                                  this.state.videoDetailsFirst
                                    .next_video_details.default_image
                                }
                                alt={
                                  this.state.videoDetailsFirst
                                    .next_video_details.title
                                }
                                className="next-video-img"
                              />
                              <div className="center-play-icon-small-sec">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/img/video-player-icons/play-icon.svg"
                                  }
                                  alt=""
                                  className="center-play-icon"
                                />
                              </div>
                            </div>
                            <div className="next-video-details-sec">
                              <h6 className="title">
                                {
                                  this.state.videoDetailsFirst
                                    .next_video_details.title
                                }
                              </h6>
                              <p className="desc">
                                {
                                  this.state.videoDetailsFirst
                                    .next_video_details.description
                                }
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </Media>
                  ) : (
                    ""
                  )}
                  {this.state.videoDetailsFirst.is_series == 1 ? (
                    <Media as="li" className="next-season-icon">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/video-player-icons/season-icon.svg"
                        }
                        alt=""
                        className="control-icon"
                      />
                      <div className="hoverable-next-season-icon">
                        <div className="header-sec">
                          <h6>Season 1</h6>
                        </div>
                        <div className="body-sec">
                          <Accordion
                            defaultActiveKey={
                              this.state.videoDetailsFirst.admin_video_id
                            }
                          >
                            {this.state.videoDetailsFirst.video_playlist
                              .length > 0
                              ? this.state.videoDetailsFirst.video_playlist.map(
                                  (playlist) => (
                                    <Card className="season-card-sec">
                                      <Card.Header>
                                        <h2 className="mb-0">
                                          <Accordion.Toggle
                                            as={Button}
                                            variant="link"
                                            eventKey={playlist.id}
                                            className="btn-block text-left title"
                                          >
                                            {playlist.title}
                                          </Accordion.Toggle>
                                        </h2>
                                      </Card.Header>
                                      <Accordion.Collapse
                                        eventKey={playlist.id}
                                      >
                                        <Link
                                          onClick={(event) =>
                                            this.handlePlayVideo(
                                              event,
                                              playlist.id
                                            )
                                          }
                                          to="#"
                                        >
                                          <Card.Body className="season-card-body-sec">
                                            <div className="next-video-sec">
                                              <Image
                                                src={playlist.default_image}
                                                alt={playlist.title}
                                                className="next-video-img"
                                              />
                                              <div className="center-play-icon-small-sec">
                                                <Image
                                                  src={
                                                    window.location.origin +
                                                    "/assets/img/video-player-icons/play-icon.svg"
                                                  }
                                                  alt=""
                                                  className="center-play-icon"
                                                />
                                              </div>
                                            </div>
                                            <div className="next-video-details-sec">
                                              <h6 className="title">
                                                {playlist.title}
                                              </h6>
                                              <p className="desc">
                                                {playlist.description}
                                              </p>
                                            </div>
                                          </Card.Body>
                                        </Link>
                                      </Accordion.Collapse>
                                    </Card>
                                  )
                                )
                              : ""}
                          </Accordion>
                        </div>
                      </div>
                    </Media>
                  ) : (
                    ""
                  )}
                  {!loadingPlayerInfo &&
                  this.state.videoPlayerInfo.audio_tracks.length > 0 ? (
                    <Media as="li" className="sub-audio-icon">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/video-player-icons/subtitle-icon.svg"
                        }
                        alt=""
                        className="control-icon"
                      />
                      <div className="hoverable-sub-audio-icon">
                        <div className="audio-sec">
                          <h6 className="title-head">Audio</h6>
                          <ul className="list-unstyled sub-audio-list">
                            {this.state.videoPlayerInfo.audio_tracks.map(
                              (audio_track, key) => (
                                <Media as="li">
                                  <Link
                                    to="#"
                                    onClick={(event) =>
                                      this.playerSetTrack(event, key)
                                    }
                                  >
                                    {key == 0 ? (
                                      <Image
                                        src={
                                          window.location.origin +
                                          "/assets/img/video-player-icons/tick.svg"
                                        }
                                        alt=""
                                        className="tick-icon"
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {audio_track.language}
                                  </Link>
                                </Media>
                              )
                            )}
                          </ul>
                        </div>
                        <div className="subtitle-sec">
                          <h6 className="title-head">Subtitles</h6>
                          <ul className="list-unstyled sub-audio-list">
                            {!loadingPlayerInfo &&
                            this.state.videoPlayerInfo.subtitles.length > 0
                              ? this.state.videoPlayerInfo.subtitles.map(
                                  (subtitle, key) => (
                                    <Media as="li">
                                      <Link
                                        to="#"
                                        onClick={(event) =>
                                          this.playerSetCaption(event, key)
                                        }
                                      >
                                        {key == 0 ? (
                                          <Image
                                            src={
                                              window.location.origin +
                                              "/assets/img/video-player-icons/tick.svg"
                                            }
                                            alt=""
                                            className="tick-icon"
                                          />
                                        ) : (
                                          ""
                                        )}
                                        {subtitle.language}
                                      </Link>
                                    </Media>
                                  )
                                )
                              : ""}
                          </ul>
                        </div>
                      </div>
                    </Media>
                  ) : (
                    ""
                  )}
                  <Media as="li" className="speed-icon">
                    <Image
                      src={
                        window.location.origin +
                        "/assets/img/video-player-icons/speed-icon.svg"
                      }
                      alt=""
                      className="control-icon"
                    />
                    <div className="hoverable-speed-icon">
                      <h5 className="speed-title-head">Playback Speed</h5>
                      <Form>
                        <div className="bar">
                          <input
                            className="bar-input"
                            type="radio"
                            name="input"
                            id="input_0"
                          />
                          <div
                            className={
                              playbackRate === 1.5
                                ? "bar-view active"
                                : "bar-view"
                            }
                            onClick={(event) =>
                              this.setPlaybackspeed(event, 1.5)
                            }
                          >
                            <label className="bar-button" for="input_0">
                              <i className="fas fa-circle"></i>
                            </label>
                            <p className="desc">1.5x</p>
                          </div>
                          <input
                            className="bar-input"
                            type="radio"
                            name="input"
                            id="input_1"
                          />
                          <div
                            className={
                              playbackRate === 1.25
                                ? "bar-view active"
                                : "bar-view"
                            }
                            onClick={(event) =>
                              this.setPlaybackspeed(event, 1.25)
                            }
                          >
                            <label className="bar-button" for="input_1">
                              <i className="fas fa-circle"></i>
                            </label>
                            <p className="desc">1.25x</p>
                          </div>
                          <input
                            className="bar-input"
                            type="radio"
                            name="input"
                            id="input_2"
                          />
                          <div
                            className={
                              playbackRate === 1.0
                                ? "bar-view active"
                                : "bar-view"
                            }
                            onClick={(event) =>
                              this.setPlaybackspeed(event, 1.0)
                            }
                          >
                            <label className="bar-button" for="input_2">
                              <i className="fas fa-circle"></i>
                            </label>
                            <p className="desc">1x(Normal)</p>
                          </div>
                          <input
                            className="bar-input"
                            type="radio"
                            name="input"
                            id="input_3"
                          />
                          <div
                            className={
                              playbackRate === 0.75
                                ? "bar-view active"
                                : "bar-view"
                            }
                            onClick={(event) =>
                              this.setPlaybackspeed(event, 0.75)
                            }
                          >
                            <label className="bar-button" for="input_3">
                              <i className="fas fa-circle"></i>
                            </label>
                            <p className="desc">0.75x</p>
                          </div>
                          <input
                            className="bar-input"
                            type="radio"
                            name="input"
                            id="input_4"
                          />
                          <div
                            className={
                              playbackRate === 0.5
                                ? "bar-view active"
                                : "bar-view"
                            }
                            onClick={(event) =>
                              this.setPlaybackspeed(event, 0.5)
                            }
                          >
                            <label className="bar-button" for="input_4">
                              <i className="fas fa-circle"></i>
                            </label>
                            <p className="desc">0.5x</p>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </Media>
                  <Media as="li" id="player-pip-on">
                    <Link
                      to="#"
                      onClick={(event) => {
                        this.openPip(event);
                      }}
                    >
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/video-player-icons/pip.svg"
                        }
                        alt=""
                        className="control-icon"
                      />
                    </Link>
                  </Media>
                  <Media as="li" id="player-fullscreen-on">
                    <Link
                      to="#"
                      onClick={(event) => {
                        if (document.fullscreenElement) {
                          this.closeFullscreen(event);
                        } else {
                          this.openFullscreen(event);
                        }
                      }}
                    >
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/video-player-icons/full-screen-icon.svg"
                        }
                        alt=""
                        className="control-icon"
                      />
                    </Link>
                  </Media>
                </ul>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

export default VideoPLayerIndex;
