import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import api from "../../Environment";
import ToastDemo from "./toaster";

class Helper extends Component {
  state = {
    data: {},
    errors: {},
    activeProfile: [],
    loading: true,
    videoDetailsFirst: null,
    loadingFirst: true,
    videoDetailsSecond: null,
    suggestion: null,
    loadingSuggestion: true,
    maindata: null,
    banner: null,
    wishlistApiCall: false,
    wishlistResponse: null,
    redirect: false,
    redirectPPV: false,
    redirectPaymentOption: false,
    redirectSubscription: false,
    loadingHomeSecondSection: false,
    homeSecondData: null,
    addNewProfileOption: null,
    onPlayStarted: false,
    isDoublePayment: false
  };

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  getUserDetails() {
    api.postMethod("profile").then((response) => {
      if (response.data.success === true) {
        let data = response.data.data;
        this.setState({ loading: false, data: data });
      }
    });
  }

  viewProfiles() {
    api.postMethod("sub_profiles").then((response) => {
      if (response.data.success === true) {
        let activeProfile = response.data.data.sub_profiles;
        this.setState({
          loading: false,
          activeProfile: activeProfile,
          addNewProfileOption: response.data.data.is_new_sub_profile_allowed,
        });
      } else {
        this.errorCodeChecker(response.data.error_code);
      }
    });
  }

  singleVideoFirst(inputData) {
    api
      .postMethod("videos/view", inputData)
      .then((response) => {
        if (response.data.success === true) {
          let videoDetailsFirst = response.data.data;

          this.setState({
            loadingFirst: false,
            videoDetailsFirst: videoDetailsFirst,
          });
          this.singleVideoSecond(inputData);
        } else {
        }
      })
      .catch(function(error) {});
  }
  async onlySingleVideoFirst(inputData) {
    await api
      .postMethod("videos/view", inputData)
      .then((response) => {
        if (response.data.success === true) {
          let videoDetailsFirst = response.data.data;

          this.setState({
            loadingFirst: false,
            videoDetailsFirst: videoDetailsFirst,
          });
        } else {
          this.setState({ videoDetailsFirst: response.data });
        }
      })
      .catch(function(error) {});
  }
  singleVideoSecond(inputData) {
    api
      .postMethod("videos/view/second", inputData)
      .then((response) => {
        if (response.data.success === true) {
          let videoDetailsSecond = response.data.data;

          this.setState({
            loadingSecond: false,
            videoDetailsSecond: videoDetailsSecond,
          });
        } else {
        }
      })
      .catch(function(error) {});
  }
  suggestion(inputData) {
    api
      .postMethod("suggestions", inputData)
      .then((response) => {
        if (response.data.success === true) {
          let suggestion = response.data.data;

          this.setState({
            loadingSuggestion: false,
            suggestion: suggestion,
          });
        } else {
        }
      })
      .catch(function(error) {});
  }
  homeFirstSection(inputData) {
    api
      .postMethod("home_first_section", inputData)
      .then((response) => {
        if (response.data.success === true) {
          let maindata = response.data.data;
          let banner = response.data.banner;

          this.setState({
            loading: false,
            maindata: maindata,
            banner: banner,
          });
        } else {
          let errorHandle = 1;
          this.setState({ errorHandle });
          this.errorCodeChecker(response.data.error_code);
        }
        this.homeSecondSection(inputData);
      })
      .catch(function(error) {});
  }

  homeSecondSection(inputData) {
    api
      .postMethod("home_second_section", inputData)
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            loadingHomeSecondSection: false,
            homeSecondData: response.data.data,
          });
        } else {
          let errorHandle = 1;
          this.setState({ errorHandle });
        }
      })
      .catch(function(error) {});
  }

  wishlistUpdate(inputData) {
    api.postMethod("wishlists/operations", inputData).then((response) => {
      if (response.data.success === true) {
        ToastDemo(this.props.toastManager, response.data.message, "success");
        this.setState({
          wishlistResponse: response.data,
          wishlistApiCall: true,
        });
      } else {
        ToastDemo(
          this.props.toastManager,
          response.data.error_messages,
          "error"
        );
      }
    });
  }

  redirectStatus(StatusData) {
    if (this.state.data.user_type === 0 && StatusData.should_display_ppv === 0) {
      this.setState({ redirectSubscription: true });
    } 
    if (this.state.data.user_type === 0 && StatusData.should_display_ppv === 1) {
      this.setState({ redirectSubscription: true , isDoublePayment : true});
    }
    if (this.state.data.user_type === 1 && StatusData.should_display_ppv === 1) {
      this.setState({ redirectPPV: true });
    }
    if (this.state.data.user_type === 1 && StatusData.should_display_ppv === 0) {
      this.setState({ redirect: true });
    }
  }

  renderRedirectPage(videoDetailsFirst) {
    if (this.state.redirect) {

      this.setState({ redirect: false });
      return (
        <Redirect
          to={{
            pathname: `/video-player/${this.makeRandomString(30)}`,
            state: { videoDetailsFirst: videoDetailsFirst },
          }}
        />
      );
    } else if (this.state.redirectPPV) {

      this.setState({ redirectPPV: false });
      document.getElementById("header").style.zIndex = 1;
      this.setState({ showModalPPV: true });
      this.setState({ videoDetailsFirst: videoDetailsFirst });
    } else if (this.state.redirectSubscription) {

      this.setState({ redirectSubscription: false });
      document.getElementById("header").style.zIndex = 1;
      this.setState({ showModalSubscription: true });
    } else {

      return null;
    }
  }

  errorCodeChecker(errorCode) {
    // console.log(errorCode);
    if (
      errorCode == 3000 ||
      errorCode == 3002 ||
      errorCode == 905 ||
      errorCode == 133 ||
      errorCode == 103 ||
      errorCode == 104
    ) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userId");
      localStorage.removeItem("userLoginStatus");
      localStorage.removeItem("push_status");
      localStorage.removeItem("active_profile_id");
      localStorage.removeItem("userType");

      setTimeout(function() {
        window.location = "/login";
      }, 1000);
    }
  }

  convertToSlug( str ) {
    str = str.replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ')
             .toLowerCase();
    str = str.replace(/^\s+|\s+$/gm,'');
    str = str.replace(/\s+/g, '-');
    return str;
  }

  makeRandomString(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  formatRupiah(amount) {
    const formatter = new Intl.NumberFormat('id-ID', {
      minimumFractionDigits: 0,
    });
  
    return formatter.format(amount);
  }
  
  secondsToTime(duration) {
    if (duration < 0 || !duration) {
      return "00:00:00"; // Jika durasi negatif, kembalikan waktu awal "00:00:00"
    }

    let jam = Math.floor(duration / 3600);
    let sisa = duration % 3600;
    let menit = Math.floor(sisa / 60);
    let detik = Math.floor(sisa % 60);

    let waktu = `${jam.toString().padStart(2, "0")}:${menit
      .toString()
      .padStart(2, "0")}:${detik.toString().padStart(2, "0")}`;
    return waktu;
  }

  timeToSeconds(timeString) {
    const timeParts = timeString.split(':');
    if (timeParts.length === 3) {
      const hours = parseInt(timeParts[0], 10);
      const minutes = parseInt(timeParts[1], 10);
      const seconds = parseInt(timeParts[2], 10);
      return hours * 3600 + minutes * 60 + seconds;
    } else {
      // Handle invalid time format
      return 0;
    }
  }

  logoutUser(type,message){
    localStorage.setItem("userId", 4);
    localStorage.setItem("accessToken", '2y10zZGNPSgkYdTMPjSftoy7Au1mVtSaUfw273vZQx3iqVQmwM1TKGG');
    localStorage.setItem("userType", 0);
    localStorage.setItem("push_status", 1);
    localStorage.setItem("username", 0);
    localStorage.setItem("active_profile_id", 1);

    if (type === 'home') {
      alert(message)
      this.props.history.push("/");
    } else {
      this.props.history.push("/");
      alert(message)
    }
  }

  userSession = (type) => {
    api
      .postMethod("check_session")
      .then((response) => {
        if (response.data.status === 're-login') {
          this.logoutUser(type,"Anda belum memiliki session, harap login kembali")
        } else if (response.data.status === true) {
          // console.log('Anda Login di Device pertama');
        } else if (response.data.status === false) {
          this.logoutUser(type,"Akun Comika.id-mu sedang digunakan di perangkat lain, Silahkan Log-out terlebih dahulu atau Log-in dengan akun lain.")
        }
      })
      .catch(function() {});
  };
}

export default Helper;
