import React from "react";
import { Modal } from 'react-bootstrap';
import './Payment.scss';
// import ReactGA from 'react-ga';
import Helper from "../../Helper/helper";

// ReactGA.modalview('/payment');

class Payment extends Helper {

        state = {
            loading : true
        }

        handleClick = (event) => {
            event.preventDefault();
              window.location.href = this.props.link_payment;
          };

        render(props) {

        return (
            <Modal className="modal-payment" show={this.props.show} onHide={() => this.props.onHide(false)}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="wrapper-payment">
                        <div className="section-content">
                            <div className="content-header">
                                    <div className="content-header-title">Payment</div>
                            </div>
                            <div className="content-order">
                                <div className="content-order-up">Order Number</div>
                                <div className="content-order-down">{this.props.dataPropsPayment.id}</div>
                            </div>
                            <div className="content-order">
                                <div className="content-order-up">Total</div>
                                <div className="content-order-down">Rp {this.formatRupiah(this.props.dataPropsPayment.amount)}</div>
                            </div>
                            <div className="content-bank">
                                <div className="row">
                                        <div className="col-md-6">
                                            <div className="content-bank-title">{this.props.dataPropsPayment.product_bank_code}</div>
                                        </div>
                                        <div className="col-md-6">
                                            <img src={"https://kit.espay.id/images/products/" + this.props.dataPropsPayment.product_bank_code + ".png"} className="content-bank-image" />
                                        </div>
                                    </div>
                            </div>
                            <div className="content-buy">
                                <button id="pay-now" className="btn-buy" onClick={this.handleClick}>Pay Now</button>
                            </div>
                            
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default Payment;