// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import "./IsChromeModal.scss";
// import ReactGA from "react-ga";
import Helper from "../../Helper/helper";

class IsChromeModal extends Helper {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render(props) {
    return (
      <Modal
        centered
        className="is-chrome-modal"
        show={this.props.show}
        onHide={() => this.props.onHide(false)}
      >
        <Modal.Body>
          <div className="circle">
            <img
              className="icon-chrome"
              src={
                window.location.origin +
                "/assets/img/icon-chrome.png"
              }
              alt="Icon Chrome"
            />
            <img
              className="icon-change"
              src={
                window.location.origin +
                "/assets/img/icon-change.png"
              }
              alt="Icon Change"
            />
          </div>
          <div className="title">Harap gunakan google chrome</div>
          <div className="sub-title">
            Untuk pengalaman pengguna terbaik harap ganti browser dengan Google
            Chrome.
          </div>
          <button
            className="btn-close"
            onClick={() => this.props.onHide(false)}
          >
            Tutup
          </button>
        </Modal.Body>
      </Modal>
    );
  }
}

export default IsChromeModal;
