import { Component } from "react";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";

import { translate } from "react-multi-lang";

class Logout extends Component {
    componentDidMount() {
        localStorage.setItem("userId", 4);
      localStorage.setItem("accessToken", '2y10zZGNPSgkYdTMPjSftoy7Au1mVtSaUfw273vZQx3iqVQmwM1TKGG');
      localStorage.setItem("userType", 0);
      localStorage.setItem("push_status", 1);
      localStorage.setItem("username", 0);
      localStorage.setItem(
        "active_profile_id",
        1
      );

        this.props.history.push("/");
        ToastDemo(this.props.toastManager,this.props.t("logout_success"), "success");
    }
    render() {
        return null;
    }
}

export default withToastManager(translate(Logout));
