import React from "react";
import { Link } from "react-router-dom";
import Helper from "../../Helper/helper";
import api from "../../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import configuration from "react-global-configuration";
import { translate } from "react-multi-lang";
import "./UserHeader.scss";

const $ = window.$;

class UserHeader extends Helper {
  constructor(props) {
    super(props);
  }
  state = {
    loadingInformation: true,
    loadingCategory: true,
    loadingCasts: true,
    categories: null,
    playButtonClicked: false,
    suggestions: null,
    mobileSidebar: false,
    loadingSuggesstion: true,
    displaySuggesstion: "none",
    searchInputFocusClass: "",
    casts: null,
    informationList: null,
  };

  componentDidMount() {
    this.getCategory();
    this.getCastList();
    this.getInformation();
  }

  async getCastList() {
    await api.postMethod("cast_crews/list").then((response) => {
      this.setState({ casts: response.data, loadingCasts: false });
    });
  }

  async getCategory() {
    await api.postMethod("v4/categories/list").then((response) => {
      this.setState({
        loadingCategory: false,
        categories: response.data.data,
      });
    });
  }

  async getInformation() {
    await api.getMethod("pages/list").then((response) => {
      this.setState({
        loadingInformation: false,
        informationList: response.data.data,
      });
    });
  }

  handleSearchChange = ({ currentTarget: input }) => {
    if (input.value != "") {
      this.setState({ displaySuggesstion: "block" });
    } else {
      this.setState({ displaySuggesstion: "none", searchInputFocusClass: "" });
    }
    api
      .postMethod("search_videos", { key: input.value })
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            suggestions: response.data.data,
            loadingSuggesstion: false,
          });
          if (response.data.data.length <= 0) {
            this.setState({
              searchInputFocusClass: "",
            });
          }
        } else {
        }
      })
      .catch(function(error) {});
  };

  searchInputFocus = ({ currentTarget: input }) => {
    this.setState({ searchInputFocusClass: "search-focus" });
  };

  toggleMobileSidebar = () => {
    this.setState({
      mobileSidebar: !this.state.mobileSidebar,
    });
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  render() {
    const { t } = this.props;

    const {
      loadingInformation,
      loadingCategory,
      loadingCasts,
      categories,
      suggestions,
      casts,
      loadingSuggesstion,
      informationList,
    } = this.state;

    if (this.state.playButtonClicked) {
      const returnToVideo = this.renderRedirectPage(
        this.state.videoDetailsFirst
      );

      if (returnToVideo != null) {
        return returnToVideo;
      }
    }

    return (
      <div>
        <nav
          className="navbar navbar-expand navbar-dark main-nav fixed-top"
          id="header"
        >
          <span
            className="menu-icon"
            id="menu_icon"
            onClick={() => this.toggleMobileSidebar()}
          >
            <img
              src={window.location.origin + "/assets/img/menu.png"}
              alt="menu_img"
            />
          </span>
          <Link className="navbar-brand abs" to="/home">
            <img
              src={window.location.origin + "/assets/img/logo-comika.png"}
              className="logo-img desktop-logo"
              alt={configuration.get("configData.site_name")}
            />
            <img
              src={window.location.origin + "/assets/img/logo-comika.png"}
              className="logo-img mobile-logo"
              alt={configuration.get("configData.site_name")}
            />
          </Link>
          <ul className="navbar-nav desktop-nav ">
            <li className="nav-item dropdown" key="browse-header-cast">
              {loadingCasts ? (
                <li className="nav-item dropdown" key="browse-header-comika-">
                  <Link
                    className="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    to="#"
                  >
                    Comika
                  </Link>
                </li>
              ) : casts.length > 0 ? (
                <li className="nav-item dropdown " key="browse-header-comika">
                  <Link
                    className="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    to="#"
                  >
                    Comika
                  </Link>
                  <div className="dropdown-menu browse menu-item">
                    <Link className="dropdown-item cast-item" to="/all-comika">
                      All Comika
                    </Link>

                    {casts.map((cast) => (
                      <Link
                        key={cast.id}
                        className="dropdown-item cast-item"
                        to={{
                          pathname: "/view-all",

                          state: {
                            cast_crew_id: cast.id,
                            title: cast.name,
                            videoType: "cast",
                            apiURL: "v4/cast_crews/videos",
                          },
                        }}
                        href=""
                      >
                        {cast.name}
                      </Link>
                    ))}
                  </div>
                </li>
              ) : (
                ""
              )}
            </li>

            {loadingCategory
              ? ""
              : categories.length > 0
              ? categories.slice(0, 5).map((category, index) => (
                  <li className="nav-item" key={`series-header-/${index}`}>
                    <Link
                      className="nav-link"
                      to={{
                        pathname: "/view-all",
                        state: {
                          url_type: category.url_type,
                          url_type_id: category.url_type_id,
                          page_type: "HOME",
                          title: category.name,
                        },
                      }}
                    >
                      {category.name}
                    </Link>
                  </li>
                ))
              : ""}

            <li className="nav-item" key="movies-header">
              <a
                className="nav-link"
                href="https://dd.comika.id/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download
              </a>
            </li>

            {loadingInformation ? (
              <li className="nav-item dropdown" key="browse-header-info-">
                <Link
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  to="#"
                >
                  Informasi
                </Link>
              </li>
            ) : informationList.length > 0 ? (
              <li className="nav-item dropdown" key="browse-header-info">
                <Link
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  to="#"
                >
                  Informasi
                </Link>
                <div className="dropdown-menu browse">
                  {informationList && informationList.length > 0
                    ? informationList.map((static_page1, index) => (
                        <li className="nav-item" key={`static-page-/${index}`}>
                          <Link
                            className="dropdown-item"
                            to={{
                              pathname: `/page/${static_page1.unique_id}`,
                              state: {
                                unique_id: static_page1.unique_id,
                              },
                            }}
                          >
                            {static_page1.title}
                          </Link>
                        </li>
                      ))
                    : ""}
                </div>
              </li>
            ) : (
              ""
            )}
            <li className="nav-item" key="movies-header">
              <a
                className="nav-link"
                href="https://dd.comika.id/blog/"
                target="_blank"
              >
                Blog
              </a>
            </li>
          </ul>
          <ul className="navbar-nav ml-auto desktop-nav">
            <li className="nav-item">
              <form className="search-suggestion-form">
                <div className="search-input-container center">
                  <div className="search-input-container__inner">
                    <input
                      type="text"
                      name="search"
                      placeholder="Search..."
                      className={
                        "form-control search-focus" + ""
                        // this.state.searchInputFocusClass
                      }
                      onChange={this.handleSearchChange}
                      onClick={this.searchInputFocus}
                      onKeyPress={this.handleKeyPress}
                    />
                    <div
                      className="suggestions-container center"
                      style={{
                        maxHeight: "207.95px",
                        display: this.state.displaySuggesstion,
                      }}
                    >
                      <ul>
                        {loadingSuggesstion ? (
                          t("loading")
                        ) : (
                          <>
                            {suggestions.admin_videos.length > 0 ||
                            suggestions.cast_crews.length > 0 ||
                            suggestions.tags.length > 0 ? (
                              <>
                                {suggestions.admin_videos.map(
                                  (suggestion, index) => (
                                    <li>
                                      <Link
                                        key={`suggestion-video/${index}`}
                                        className=""
                                        to={{
                                          pathname: `/video-details/${suggestion.admin_video_id}`,
                                          state: {
                                            admin_video_id:
                                              suggestion.admin_video_id,
                                          },
                                        }}
                                        href=""
                                      >
                                        {suggestion.title}
                                      </Link>
                                    </li>
                                  )
                                )}
                                {suggestions.cast_crews.map(
                                  (suggestion, index) => (
                                    <li>
                                      <Link
                                        key={`suggestion-cast/${index}`}
                                        className=""
                                        to={{
                                          pathname: "/view-all",

                                          state: {
                                            cast_crew_id: suggestion.id,
                                            title:
                                              "Search Results for: " +
                                              suggestion.name,
                                            videoType: "cast",
                                            apiURL: "v4/cast_crews/videos",
                                          },
                                        }}
                                        href=""
                                      >
                                        {suggestion.name}
                                      </Link>
                                    </li>
                                  )
                                )}
                                {suggestions.tags.map((suggestion, index) => (
                                  <li>
                                    <Link
                                      key={`suggestion-tag/${index}`}
                                      className=""
                                      to={{
                                        pathname: "/view-all",

                                        state: {
                                          tag_id: suggestion.id,
                                          title:
                                            "Search Results for: " +
                                            suggestion.name,
                                          videoType: "tags",
                                          apiURL: "v4/tags/videos",
                                        },
                                      }}
                                      href=""
                                    >
                                      {suggestion.name}
                                    </Link>
                                  </li>
                                ))}
                              </>
                            ) : (
                              <li className="" key="suggestion-admin-no-result">
                                <span>{t("no_results_found")}</span>
                              </li>
                            )}
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </form>
            </li>
            <li className="nav-item gift mr-2 ml-2 ">
              <Link
                className="nav-link"
                to={{
                  pathname: "/view-all",
                  state: {
                    url_type: "URL_TYPE_WISHLIST",
                    url_type_id: 0,
                    page_type: "HOME",
                    title: "My List",
                  },
                }}
              >
                <i className="fas fa-heart"></i>
              </Link>
            </li>
            {localStorage.getItem("userId") == 4 ? (
              <Link to="/login" className="signin-btn">
                {t("signin")}
              </Link>
            ) : (
              <li className="nav-item dropdown" key="browse-header-uname">
                <Link
                  className="nav-link"
                  to="#"
                  data-toggle="dropdown"
                >
                <img
                  className="icon-user"
                  src={
                    window.location.origin +
                    "/assets/img/logo-user.png"
                  }
                  alt="Icon User"
                />
                </Link>
                <div className="dropdown-menu profile-drop">
                  <Link className="dropdown-item" to="/account">
                    {t("account")}
                  </Link>
                  <Link className="dropdown-item" to={"/logout"}>
                    {t("signout")}
                  </Link>
                </div>
              </li>
            )}
          </ul>
        </nav>
        <div className="header-height" />

        <div
          className="mobile-sidebar"
          id="menu_content"
          style={{
            display: this.state.mobileSidebar ? "block" : "none",
          }}
        >
          <div className="sidebar-content">
            <ul className="sidebar-menu" id="mobile-side-menu">
              {localStorage.getItem("userId") == 4 ? (
                <li key="account-sidemenu">
                  <Link to="/login" className="signin-btn">
                    {t("signin")}
                  </Link>
                </li>
              ) : (
                <div>
                  <li key="account-sidemenu">
                    <Link to="/account">{"account"}</Link>
                  </li>
                  <li key="logout-sidemenu">
                    <Link to={"/logout"}>{t("logout")}</Link>
                  </li>
                </div>
              )}
              <li className="line" />

              {loadingCategory
                ? ""
                : categories.length > 0
                ? categories.slice(0, 5).map((category, index) => (
                    <li className="nav-item" key={`series-header-/${index}`}>
                      <Link
                        to={{
                          pathname: "/view-all",
                          state: {
                            url_type: category.url_type,
                            url_type_id: category.url_type_id,
                            page_type: "HOME",
                            title: category.name,
                          },
                        }}
                      >
                        {category.name}
                      </Link>
                    </li>
                  ))
                : ""}

                <li className="nav-item" key="movies-header">
                  <a
                    href="https://dd.comika.id/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download
                  </a>
                </li>

              {loadingInformation ? (
                ""
              ) : informationList.length > 0 ? (
                <li className="nav-item dropdown" key="browse-header-static">
                  <Link
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    to="#"
                  >
                    Informasi
                  </Link>
                  <div className="dropdown-menu browse">
                    {informationList && informationList.length > 0
                      ? informationList.map((static_page1, index) => (
                          <li className="nav-item" key={`static-page/${index}`}>
                            <Link
                              className="dropdown-item"
                              to={{
                                pathname: `/page/${static_page1.unique_id}`,
                                state: {
                                  unique_id: static_page1.unique_id,
                                },
                              }}
                            >
                              {static_page1.title}
                            </Link>
                          </li>
                        ))
                      : ""}
                  </div>
                </li>
              ) : (
                ""
              )}
              <li className="nav-item" key="movies-header">
                <a href="https://dd.comika.id/blog/" target="_blank">
                  Blog
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default withToastManager(translate(UserHeader));
