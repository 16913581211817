import React from "react";
import { Link } from "react-router-dom";
import Helper from "../Helper/helper";
import ContentLoader from "../Static/contentLoader";
import AccountLoader from "../Loader/AccountLoader"

import { translate } from "react-multi-lang";
import api from "../../Environment";
import './all-comika.css';

class AllComika extends Helper {
    state = {
        data: null,
        loading: true
    };

    componentDidMount() {
        api.postMethod("cast_crews/list").then((response) => {
    
        this.setState({data: response.data,loading: false})
        })
    }
    render() {
        const { t } = this.props;

        const { loading, data } = this.state;

        return (
            <div>
                <div className="main padding-top-md">
                    <div className="top-bottom-spacing resp-align-center">
                            <div className="row">
                                <div className="col-sm-12 col-md-11 col-lg-10 col-xl-9 auto-margin">
                                    <div className="account-title-sec">
                                        <h1 className="comika-title">All Comika</h1>
                                    </div>

                                    <div className="row">
                                        {loading
                                        ? ""
                                        : data.map((cast, index) =>
                                            cast.length === 0 ? "" : 
                                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4">
                                                <div className="cast-section">
                                                    <Link
                                                        key={cast.id}
                                                        to={{
                                                            pathname: "/view-all",
                                        
                                                            state: {
                                                                cast_crew_id:
                                                                cast.id,
                                                                title: cast.name,
                                                                videoType: "cast",
                                                                apiURL: "v4/cast_crews/videos"
                                                            }
                                                        }}
                                                        href=""
                                                        >
                                                        <img src={cast.image} alt="" className="cast-img"/>
                                                    {cast.name}
                                                    </Link>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                    </div>
                    <div className="clearfix" />
                </div>
            </div>
        );
    }
}

export default translate(AllComika);
