import React from "react";
import { Link } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";
import api from "../../../Environment";
import ToastDemo from "../../Helper/toaster";
import Helper from "../../Helper/helper";
import { translate, t } from "react-multi-lang";
import "./VideoOverview.scss";
import PopupButton from "../../PopupButton/PopupButton";
import Subscription from "../../Modals/AddOnModal/Subscription";
import PPVCart from "../../Modals/ModalPPV/PPVCart";
import Rating from "./Rating";
import IsChromeModal from "../../Modals/IsChromeModal/IsChomeModal";
import { isChrome } from "react-device-detect";

const DATE_OPTIONS = {
  year: "numeric",
  month: "short",
};

class VideoOverView extends Helper {
  state = {
    inputData: {
      admin_video_id: this.props.videoDetailsFirst.admin_video_id,
    },
    likeApiCall: false,
    dislikeApiCall: false,
    likeReponse: null,
    disLikeReponse: null,
    wishlistApiCall: false,
    wishlistResponse: {
      wishlist_id: null,
    },
    tags: [],
    redirect: false,
    redirectPPV: false,
    redirectPaymentOption: false,
    playButtonClicked: false,
    wishlistStatusCheck: 0,
    showModalSubscription: false,
    showModalPPV: false,
    isDoublePayment: false,
    data: null,
    callLikeDislike: false,
    responseLikeDislike: null,
    showModal: false,
  };

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleCloseSubscription() {
    this.setState({ showModalSubscription: false });
  }

  handleClosePPV() {
    this.setState({ showModalPPV: false });
  }

  componentDidMount() {
    this.getUserDetails();
    this.setState({ playButtonClicked: false });

    let wishlistStatusCheck = 0;
    if (this.props.videoDetailsFirst.wishlist_status == 1) {
      wishlistStatusCheck = 1;
    } else {
      wishlistStatusCheck = 0;
    }
    this.setState({ wishlistStatusCheck });
  }
  handleOnClickLike = (event) => {
    event.preventDefault();
    if (localStorage.getItem("userId") !== "4") {
      api.postMethod("videos/like", this.state.inputData).then((response) => {
        if (response.data.success === true) {
          ToastDemo(
            this.props.toastManager,
            "You liked this Video!",
            "success"
          );
          this.setState({
            responseLikeDislike: response.data.data,
            callLikeDislike: true,
          });
        } else {
          ToastDemo(
            this.props.toastManager,
            response.data.error_messages,
            "error"
          );
        }
      });
    } else {
      alert("Anda belum login");
      window.location = "/login";
    }
  };

  handleOnClickDislike = (event) => {
    event.preventDefault();
    if (localStorage.getItem("userId") !== "4") {
      api
        .postMethod("videos/dis_like", this.state.inputData)
        .then((response) => {
          if (response.data.success === true) {
            ToastDemo(
              this.props.toastManager,
              "You Disliked this Video!",
              "success"
            );
            this.setState({
              responseLikeDislike: response.data.data,
              callLikeDislike: true,
            });
          } else {
            ToastDemo(this.props.toastManager, response.data.error, "error");
          }
        });
    } else {
      alert("Anda belum login");
      window.location = "/login";
    }
  };

  handleWishList = (event) => {
    event.preventDefault();

    // this.wishlistUpdate(this.state.inputData);
    if (localStorage.getItem("userId") !== "4") {
      api
        .postMethod("wishlists/operations", this.state.inputData)
        .then((response) => {
          if (response.data.success) {
            ToastDemo(
              this.props.toastManager,
              response.data.message,
              "success"
            );
            this.setState({
              wishlistResponse: response.data,
              wishlistApiCall: true,
            });
            if (response.data.wishlist_id != null) {
              this.setState({
                wishlistStatusCheck: 1,
              });
            } else {
              this.setState({
                wishlistStatusCheck: 0,
              });
            }
          } else {
            ToastDemo(
              this.props.toastManager,
              response.data.error_messages,
              "error"
            );
          }
        });
    } else {
      alert("Anda belum login");
      window.location = "/login";
    }
  };

  handlePlayVideo = () => {
    if (isChrome) {
      if (localStorage.getItem("userId") !== "4") {
        this.redirectStatus(this.props.videoDetailsFirst);
        this.setState({ playButtonClicked: true });
      } else {
        alert("Anda belum login");
        window.location = "/login";
      }
    } else {
      if (localStorage.getItem("userId") !== "4") {
        this.setState({
          showModal: true,
        });
      } else {
        alert("Anda belum login");
        window.location = "/login";
      }
    }
  };
  render() {
    const { videoDetailsFirst } = this.props;
    const userId = localStorage.getItem("userId");

    const {
      likeReponse,
      likeApiCall,
      disLikeReponse,
      dislikeApiCall,
      wishlistApiCall,
      wishlistResponse,
      wishlistStatusCheck,
      showModalSubscription,
      showModalPPV,
      isDoublePayment,
      callLikeDislike,
      responseLikeDislike,
      showModal,
    } = this.state;

    // const tags = [
    //   'Bundling',
    //   'Digital Download',
    //   'Padji Pragiwaksono',
    //   'AGUSTUS 2021',
    //   'Comika Talent Management',
    //   'Keadaan Kahar'
    // ]

    if (this.state.playButtonClicked) {
      const returnToVideo = this.renderRedirectPage(
        this.props.videoDetailsFirst
      );

      if (returnToVideo != null) {
        return returnToVideo;
      }
    }
    return (
      <div className="slider-topbottom-spacing">
        <div className="overview-content">
          <h1 className="banner_video_title"><marquee>{videoDetailsFirst.title}</marquee></h1>
          <h4 className="banner_video_details">
            <span className="green-clr">
              {new Date(videoDetailsFirst.publish_time).toLocaleDateString(
                "en-US",
                DATE_OPTIONS
              )}
            </span>
            <span className="grey-box">
              {videoDetailsFirst.age}
              <i className="fas fa-plus small" /> /{" "}
              {videoDetailsFirst.watch_count}{" "}
              <span className="small">Views</span>
            </span>
            <span>{videoDetailsFirst.duration}</span>
            <Rating value={videoDetailsFirst.ratings} />
          </h4>
          <h4 className="banner_video_details">
            {/* <span> buat running text febri
              <i className="far fa-thumbs-up" />
            </span>
            <span className="mr-2">
              {likeApiCall ? likeReponse.like_count : videoDetailsFirst.likes}
            </span>
            <span>
              <i className="far fa-thumbs-down" />
            </span>
            <span className="mr-2">
              {dislikeApiCall
                ? disLikeReponse.dislike_count
                : videoDetailsFirst.dislikes}
            </span> */}
            {videoDetailsFirst.should_display_ppv == 1 ? (
              <span className="red-box">
                {videoDetailsFirst.currency}{" "}
                {this.formatRupiah(videoDetailsFirst.ppv_amount)}
                {/* <span className="small">Views</span> */}
              </span>
            ) : (
              ""
            )}
          </h4>
          <div className="banner-btn-sec margin-tb">
            <div
              className="btn btn-sky-blue rounded-pill btn-right-space br-0"
              onClick={this.handlePlayVideo}
              id={this.convertToSlug(videoDetailsFirst.title)}
            >
              <i className="fas fa-play mr-2" />
              {t("play")}
            </div>
            <Link
              to="#"
              onClick={this.handleWishList}
              className="btn rounded-pill color-grey btn-right-space"
            >
              {wishlistStatusCheck == 1 ? (
                <div>
                  <i className="" style={{ display: "none" }} />
                  <img
                    src={window.location.origin + "/images/tick.png"}
                    className="mr-2"
                  />
                  {t("my_list")}
                </div>
              ) : (
                <div>
                  <i className="" style={{ display: "none" }}></i>
                  <img
                    src={window.location.origin + "/images/add.png"}
                    className="mr-2"
                  />
                  {t("my_list")}
                </div>
              )}
            </Link>

            {/* <Link
              to="#"
              onClick={this.handleWishList}
              className="btn rounded-pill  btn-outline-bolder outline-grey  btn-right-space"
            >
              
                <div>
                  
                  <i
                    
                    className="fas fa-solid fa-share-alt   mr-2"
                  ></i>
                  {t("share")}
                </div>
              
            </Link> */}
            <PopupButton videoDetail={videoDetailsFirst}></PopupButton>

            <Link
              to="#"
              onClick={this.handleOnClickLike}
              className="btn express-btn mr-2"
            >
              {callLikeDislike ? (
                responseLikeDislike.is_liked === 1 ? (
                  <i className="fas fa-thumbs-up" />
                ) : (
                  <i className="far fa-thumbs-up" />
                )
              ) : videoDetailsFirst.is_liked === 1 ? (
                <i className="fas fa-thumbs-up" />
              ) : (
                <i className="far fa-thumbs-up" />
              )}
            </Link>
            <span className="mr-2">
              {callLikeDislike
                ? responseLikeDislike.like_count
                : videoDetailsFirst.likes}
            </span>
            {/*<Link
              to="#"
              onClick={(event) => this.handleOnClickDislike(event)}
              className="btn express-btn btn-right-space"
            >
              {callLikeDislike ? (
                responseLikeDislike.is_liked === -1 ? (
                  <i className="fas fa-thumbs-down" />
                ) : (
                  <i className="far fa-thumbs-down" />
                )
              ) : videoDetailsFirst.is_liked === -1 ? (
                <i className="fas fa-thumbs-down" />
              ) : (
                <i className="far fa-thumbs-down" />
              )}
            </Link>
            <span className="mr-2">
              {callLikeDislike
                ? responseLikeDislike.dislike_count
                : videoDetailsFirst.dislikes}
              </span>*/}
          </div>
          <h4 className="slider_video_text">{videoDetailsFirst.description}</h4>
          <h4 className="slider_video_text mt-3 mb-1 title-cast">Casts:</h4>

          <h4 className="slider_video_text mt-1 text-cast">
            {videoDetailsFirst.cast_crews.map((cast, index) => (
              <Link
                to={{
                  pathname: "/view-all",

                  state: {
                    cast_crew_id: cast.cast_crew_id,
                    title: cast.name,
                    videoType: "cast",
                    apiURL: "v4/cast_crews/videos",
                  },
                }}
              >
                {videoDetailsFirst.cast_crews.length > index + 1
                  ? cast.name + ", "
                  : cast.name}
                {/* {cast.name+', '} */}
              </Link>
            ))}
          </h4>

          {videoDetailsFirst.tags.map((item) => (
            <Link
              to={{
                pathname: "/view-all",

                state: {
                  tag_id: item.tag_id,
                  title: item.name,
                  videoType: "tags",
                  apiURL: "v4/tags/videos",
                },
              }}
            >
              <span class="badge badge-pill badge-secondary font-weight-normal mr-1 badge-tag">
                {item.name}
              </span>
            </Link>
          ))}
        </div>

        <div className="modal fade confirmation-popup" id="spam-popup">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <form>
                <div className="modal-header">
                  <h4 className="modal-title">{t("report_this_video")}</h4>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>

                <div className="modal-body">
                  <p>{t("report_video_note")}</p>

                  <div className="form-check">
                    <input type="radio" id="test1" name="radio-group" checked />
                    <label htmlFor="test1">{t("sexual_content")}</label>
                  </div>
                  <div className="form-check">
                    <input type="radio" id="test2" name="radio-group" />
                    <label htmlFor="test2">{t("violent_repulsive")}</label>
                  </div>
                  <div className="form-check">
                    <input type="radio" id="test3" name="radio-group" />
                    <label htmlFor="test3">{t("hateful_or_abusive")}</label>
                  </div>
                  <div className="form-check">
                    <input type="radio" id="test4" name="radio-group" />
                    <label htmlFor="test4">{t("harmful_act")}</label>
                  </div>
                  <div className="form-check">
                    <input type="radio" id="test5" name="radio-group" />
                    <label htmlFor="test5">{t("child_abuse")}</label>
                  </div>
                  <div className="form-check">
                    <input type="radio" id="test6" name="radio-group" />
                    <label htmlFor="test6">{t("spam_or_misleading")}</label>
                  </div>
                  <div className="form-check">
                    <input type="radio" id="test7" name="radio-group" />
                    <label htmlFor="test7">{t("infringers")}</label>
                  </div>
                  <div className="form-check">
                    <input type="radio" id="test8" name="radio-group" />
                    <label htmlFor="test8">{t("caption_issue")}</label>
                  </div>
                </div>

                <div className="modal-footer">
                  <button type="button" className="btn btn-danger">
                    {t("submit")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Subscription
          show={showModalSubscription}
          onHide={this.handleCloseSubscription.bind(this)}
          isDoublePayment={isDoublePayment}
          videoDetailsFirst={videoDetailsFirst}
        />
        <PPVCart
          show={showModalPPV}
          onHide={this.handleClosePPV.bind(this)}
          videoDetailsFirst={videoDetailsFirst}
        />
        <IsChromeModal
          show={showModal}
          onHide={this.handleCloseModal.bind(this)}
        />
      </div>
    );
  }
}

export default withToastManager(translate(VideoOverView));
