import React from 'react';

const DynamicRating = ({ value }) => {
  const filledStars = Math.floor(value);
  const hasHalfStar = value % 1 !== 0;

  const renderStars = () => {
    const stars = [];

    // Mengisi bintang penuh
    for (let i = 0; i < filledStars; i++) {
      stars.push(<i className="fas fa-star" key={i} />);
    }

    // Menambahkan setengah bintang jika ada
    if (hasHalfStar) {
      stars.push(<i className="fas fa-star-half-alt" key="half" />);
    }

    // Menghitung jumlah bintang kosong yang harus ditampilkan
    const emptyStars = 5 - Math.ceil(value);
    for (let i = 0; i < emptyStars; i++) {
      stars.push(<i className="far fa-star" key={`empty-${i}`} />);
    }

    return stars;
  };

  return (
    <span className="small yellow-clr ml-1" id="dynamic-rating">
      {renderStars()}
    </span>
  );
};

export default DynamicRating;
