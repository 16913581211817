/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import "./PopupButton.scss"
import {WhatsappShareButton, WhatsappIcon} from "react-share";

class PopupButton extends Component {
  state = {
    classNew: "hide-share",
    isCopy: false,
  };

  copyLinkArtikel(link) {
    navigator.clipboard.writeText(link);
    this.setState({ isCopy: true });
    window.alert("Link Copied");
    setTimeout(() => {
      this.setState({ isCopy: false });
    }, 1000);
  }

  showShareList = event => {
    event.preventDefault();
    
    if (this.state.classNew === "show-share"){
      this.setState({ classNew: "hide-share"})
    }else{
      this.setState({ classNew: "show-share"})
    }
  }
 
  render() {
    const { videoDetail } = this.props;
    const textWhatsapp = '"Check out this video: ' + videoDetail.share_message + '"\n\n' + videoDetail.share_link;

    return (
      <div className="popup-wrapper">
        <div to="#" className={this.state.classNew + " popup"}>
          <WhatsappShareButton
            url={textWhatsapp}
           
            className=" mr-2"
          >
            <a href="#" className="">
              <WhatsappIcon size={30} round={true} />
            </a>
          </WhatsappShareButton>
          <div
            className="wrapper-button-art-share mr-2"
            onClick={() => this.copyLinkArtikel(videoDetail.share_link)}
            style={{ cursor: 'pointer' }}
          >
            <div className="container-button-art">
              <img width={30} src={process.env.PUBLIC_URL + '/assets/img/copy.png'} alt="Icon Copy"/>
            </div>
          </div>
          </div>
          <div
              onClick={(event) => this.showShareList(event)}
              id="share"
              className=" btn rounded-pill  btn-outline-bolder outline-grey  btn-right-space "
            >
              <div>
                <i
                  className="fas fa-solid fa-share-alt   mr-2"
                >
                </i>
                share
              </div>
            
          </div>
      </div>
    );
  }
}

export default PopupButton;
